<template>
  <div class="card border-primary mb-2">
    <h5 class="card-header border-primary bg-primary text-white">ข่าวสาร</h5>
    <div class="card-body">
      <div>
        <quill-editor v-model="news"></quill-editor>
      </div>
      <button class="btn btn-primary mt-2" @click="save">บันทึก</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: "",
    };
  },
  methods: {
    async save() {
      this.swal.processing();

      try {
        await this.axios({
          method: "put",
          url: "config/news",
          data: {
            news: this.news,
          },
        });

        this.swal.close();
      } catch (e) {
        console.log(e);
        this.swal.swalError();
      }
    },
  },
  async created() {
    this.swal.processing();

    try {
      let res = await this.axios({
        method: "get",
        url: "config/news",
      });

      this.news = res.data.data;

      this.swal.close();
    } catch (e) {
      console.log(e);
      this.swal.swalError();
    }
  },
};
</script>